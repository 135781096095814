.form-field {
  position: relative;

  > input,
  > select {
    background: var(--color-white);
    border: 1px solid var(--color-silver-foil);
    border-radius: 10px;
    color: var(--color-black);
    display: block;
    font-size: 16px;
    font-weight: var(--regular);
    line-height: 24px;
    padding: 15px 16px;
    position: relative;
    text-align: left;
    transition: border 0.3s ease-in-out;
    width: 100%;
    z-index: 1;

    &:focus {
      border-color: var(--color-primary);
    }

    &::placeholder {
      color: var(--color-silver-foil);
    }

    @include media('>=phone-lg') {
      font-size: 14px;
      padding: 8px 16px;
    }
  }

  > select {
    &:required:invalid {
      color: var(--color-silver-foil);
    }

    & + i {
      display: flex;
      align-items: center;
      margin: auto;
      pointer-events: none;
      position: absolute;
      right: 24px;
      top: 0;
      bottom: 0;
      transition: transform 0.3s ease-in-out;
      width: 24px;
      z-index: 1;
    }

    &:focus + i {
      transform: rotate(180deg);
    }

    option {
      color: var(--color-black);
      &[value=''][disabled] {
        display: none;
      }
    }
  }

  &.is-rounded {
    > input,
    > select {
      border-radius: 999px;
    }

    > .form-helper-text {
      margin: 4px 24px 0 24px;
    }
  }

  &.is-error {
    > input,
    > select {
      border-color: var(--color-warn);
    }
  }
}

.form-helper-text {
  font-size: 10px;
  line-height: 16px;
  color: var(--color-error);
  margin: 4px 14px 0;
}

/* Checkbox */
.checkbox-field {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  display: inline-flex;

  input[type='checkbox'],
  .checkbox-field-input {
    width: 24px;
    height: 24px;
    position: relative;
    border: 1px solid var(--color-neutral-300);
    border-radius: 4px;
    opacity: 1;

    &:checked {
      background: var(--color-primary);
      border-color: var(--color-primary);
    }

    &:checked::before {
      opacity: 1;
      transform: translate(-50%, -50%) scale(1);
    }

    &:before {
      content: '';
      display: block;
      width: 18px;
      height: 18px;
      opacity: 0;
      transform: translate(-50%, -50%) scale(0.5);
      transition: opacity 0.2s ease, transform 0.1s ease;
      position: absolute;
      top: 50%;
      left: 50%;
      background: url('./../assets/images/check.svg') no-repeat center;
      background-size: contain;
    }

    &.is-error {
      border-color: var(--color-error);
    }
  }
}
