.btn {
  font-size: rem(16);
  line-height: 1;
  letter-spacing: -1%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: var(--font);
  font-weight: var(--semi-bold);
  text-decoration: none;
  padding: rem(12) rem(18);
  border-radius: rem(56);
  border: 1px solid transparent;
  appearance: none;
  outline: none;
  cursor: pointer;

  &[disabled],
  &[disabled]:hover {
    cursor: not-allowed;
    background: var(--color-disabled);
    border-color: var(--color-disabled);
    color: var(--color-white);
  }
}

.btn-small {
  @extend .btn;
  font-size: rem(14);
  font-weight: var(--medium);
  padding: rem(10) rem(20);
}

.btn-medium {
  @extend .btn;
  font-size: rem(16);
  @include size(16, 20);
  padding: rem(10) rem(20);
}

.btn-large {
  @extend .btn;
  font-size: rem(20);
  padding: rem(16) rem(22);

  @include media('>=tablet-lg') {
    padding: rem(17) rem(32);
  }
}

.btn-primary {
  background-color: var(--color-primary);
  color: var(--color-white);
  transition: color var(--def-transition-300ms), background-color var(--def-transition-300ms);
}

.btn-primary-icon {
  padding: rem(12) rem(18) rem(12) rem(10);
  background-color: var(--color-primary);
  color: var(--color-white);
  white-space: nowrap;

  i {
    margin-right: rem(8);
  }

  @include media('<tablet-lg') {
    font-weight: var(--regular);

    i {
      margin-right: rem(6);
    }
  }

  @include media('>=tablet-lg') {
    padding: rem(12) rem(20) rem(12) rem(13);
  }
}

.btn-outline-primary {
  background-color: transparent;
  border-color: var(--color-primary);
  color: var(--color-primary);
  transition: color var(--def-transition-300ms), background-color var(--def-transition-300ms);

  @include hover {
    &:not([disabled]) {
      color: var(--color-white);
      background-color: var(--color-primary);
    }
  }

  &.is-active {
    color: var(--color-white);
    background-color: var(--color-primary);
  }
}

.btn-white {
  background-color: var(--color-white);
  color: var(--color-primary);
  font-weight: var(--semi-bold);
}

.btn-wrong {
  background-color: var(--color-error);
  color: var(--color-white);
  border: 1px solid var(--color-error);
  transition: color var(--def-transition-300ms), background-color var(--def-transition-300ms);
}

.btn-icon {
  @extend %inline-center;
  background: transparent;
  border: none;
  padding: 0;
  appearance: none;
  outline: none;
  cursor: pointer;
}

.link-btn {
  font-size: rem(14);
  letter-spacing: -1%;
  display: flex;
  padding: rem(12) rem(30);
  align-items: center;
  justify-content: center;
  border-radius: rem(50);
  font-family: var(--font);
  font-weight: var(--semi-bold);
  text-decoration: none;
  transition: all ease 0.3s;
}

.link-btn-large {
  @extend .link-btn;
  font-size: rem(18);
  padding: rem(13.5) rem(32);
}

.link-btn-secondary {
  @extend .link-btn;
  background-color: var(--color-primary-dark);
  color: var(--color-white);
  border: rem(1) solid var(--color-white);
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary);
  }
}
.link-btn-primary {
  @extend .link-btn;
  background-color: var(--color-primary);
  color: var(--color-white);
  border: rem(1) solid var(--color-white);
  cursor: pointer;

  &:hover {
    background-color: var(--color-primary-dark);
  }
}

.link-btn-outlined {
  @extend .link-btn;
  background-color: transparent;
  color: var(--color-primary);
  border: rem(1) solid var(--color-primary);

  &:hover {
    background-color: var(--color-primary);
    color: var(--color-white);
  }
}

.link-btn-outlined-white {
  @extend .link-btn;
  background-color: transparent;
  color: var(--color-white);
  border: rem(1) solid var(--color-white);
}
